* {
  scroll-behavior: smooth;
}
@font-face {
  font-family: "Berlin Sans";
  src: url("./fonts/BRLNSB.TTF");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Berlin Sans";
  src: url("./fonts/Berlin_Sans_FB_Demi_Bold.ttf");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Berlin Sans";
  src: url("./fonts/Berlin_Sans_FB_Regular.ttf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Berlin Sans";
  src: url("./fonts/berlinsans-light.ttf");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "DINPro";
  src: url("./fonts/DINPr_Bold.otf");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "DINPro";
  src: url("./fonts/DINPro_Regular.otf");
  font-weight: 400;
  font-style: normal;
}

.location-swipe {
  background: transparent !important;
}

.swiper-slide {
  background: transparent !important;
}

.wave {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
}

.empty-content{
  display: flex;
  align-items: center;
  justify-content: center;
}

.vh-height{
  min-height: 100vh;
}

.playing-anim{
  max-width: 300px;
  width: 100%;
}

.playing-anim .animation{
  width: 100%;
}

.playing-anim h2{
  text-align: center;
  font-weight: bold;
}

.wave svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 70px;
  transform: rotateY(180deg);
}

.wave .shape-fill {
  fill: #ffffff;
}

.swiper {
  width: 100%;
  height: 350px;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: white;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.img-container {
  border-radius: 2rem;
  border: none;
  box-shadow: 1px 0px 14px 12px #d3d3d340;
}

.img-container img {
  border-radius: 1rem;
}

#yellow-card {
  background-color: #fdc633;
  border-radius: 2rem;
  margin-top: -17rem;
}

@media only screen and (max-width: 600px) {
  #yellow-card {
    margin-top: -17rem;
  }
}

#yellow-card h4 {
  color: #fff;
  font-size: 3rem;
  font-family: "Berlin Sans", sans-serif;
  font-weight: 600;
}

#yellow-card p {
  color: #fff;
  font-size: 1.5rem;
  font-family: "DINPro", sans-serif;
}
.marg {
  margin-top: 1rem;
  margin-bottom: 4rem;
}

@media only screen and (max-width: 600px) {
  .marg {
    margin-top: 2rem;
  }
}

.main-container {
  margin-right: 5rem;
  margin-left: 5rem;
}

.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range {
  background-color: #97e549 !important;
}
